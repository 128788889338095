import Vue from "vue";
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index-agency')
  },
  {
    path: '*',
    component: () => import('../views/page-error')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about')
  },
  {
    path: '/portfolio',
    name: 'classic-saas',
    component: () => import('../views/portfolio')
  },
  {
    path: '/services/:serviceName',
    name: 'services',
    component: () => import('../views/services.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/portfolio')
  },
  {
    path: '/contact',
    name: 'contact-detail',
    component: () => import('../views/page-contact-detail')
  },
  {
    path: '/terms-of-services',
    name: 'contact-detail',
    component: () => import('../views/page-terms')
  },
  {
    path: '/privacy-policy',
    name: 'contact-detail',
    component: () => import('../views/page-privacy')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
